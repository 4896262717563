import React from "react"
import Fade from "react-reveal/Fade"
// import Swiper core and required modules
import { Autoplay } from "swiper"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

const TeamSlider = props => {
  return (
    <>
      <div className="">
        <>
          <Fade bottom cascade>
            <Swiper
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                640: {
                  slidesPerView: 2,
                },

                0: {
                  slidesPerView: 1,
                },
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              slidesPerView={4}
              speed={1000}
              spaceBetween={25}
              loop={true}
              modules={[Autoplay]}
            >
              {props.team.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="overflow-hidden cursor-grab">
                      <Fade bottom cascade>
                        <div>
                          <div>
                            <GatsbyImage
                              className=""
                              placeholder="blurred"
                              image={item.image}
                              alt="Softmatter"
                            />
                          </div>
                          <div className="mt-4">
                            <div className="text-mint text-2xl">
                              {item.name}
                            </div>
                            <div className="text-mint text-xl mb-1">
                              {item.title}
                            </div>
                            <div className="text-white text-base">
                              <a href={item.link} target="_blank">
                                {/* <GatsbyImage
                                  className="w-8 mt-2"
                                  image={images.li_mint}
                                  alt="Softmatter"
                                /> */}
                                <StaticImage
                                  className="w-8 mt-2"
                                  src="../../images/social/li_mint.png"
                                  alt="Softmatter"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Fade>
        </>
      </div>
    </>
  )
}

export default TeamSlider
