// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import TeamSlider from "../../components/leadership/team-slider"

const LeadershipAndTeams = ({ data }) => {
  const images = {
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),

    //team images
    Tharushi: getImage(data.Tharushi),
    Sarojini: getImage(data.Sarojini),
    Praneeth: getImage(data.Praneeth),
    Shezny_1: getImage(data.Shezny_1),
    Michael: getImage(data.Michael),
    Nadine: getImage(data.Nadine),

    Maduka: getImage(data.Maduka),
    Nuwaan: getImage(data.Nuwaan),
    Nalantha: getImage(data.Nalantha),
    Chammika: getImage(data.Chammika),
    Chinthaka: getImage(data.Chinthaka),
    Stefan: getImage(data.Stefan),
    Stefan: getImage(data.Stefan),

    Kamal: getImage(data.Kamal),
    Raweedra: getImage(data.Raweedra),
    Thilina: getImage(data.Thilina),
    Hashan: getImage(data.Hashan),
  }

  const team = [
    {
      image: images.Tharushi,
      name: "Tharushi Ranatunga",
      title: "Business Development Lead",
      link: "https://www.linkedin.com/in/tharushi-ranatunga/",
    },
    {
      image: images.Sarojini,
      name: "Sarojini Nallathamby",
      title: "Program Lead",
      link: "https://www.linkedin.com/in/sarojininallathamby/",
    },
    {
      image: images.Praneeth,
      name: "Praneeth Weeerasekara",
      title: "Program Manager",
      link: "https://www.linkedin.com/in/praneeth-weerasekara/",
    },
    {
      image: images.Shezny_1,
      name: "Shezny Kabir",
      title: "Account Manager",
      link: "https://www.linkedin.com/in/shezny-kabir-5519a452/",
    },
    {
      image: images.Michael,
      name: "Michael Alphonsus",
      title: "Growth Marketer",
      link: "https://www.linkedin.com/in/michaelalphonsus/",
    },
    // {
    //   image: images.Nadine,
    //   name: "Nadine Corera",
    //   title: "Program Manager",
    //   link: "https://www.linkedin.com/in/nadine-corera-284842118/",
    // },
  ]

  const product_team = [
    {
      image: images.Maduka,
      name: "Maduka Chandrasiri",
      title: "Product Development Lead",
      link: "https://www.linkedin.com/in/maduka-chandrasiri-78360635/",
    },
    // {
    //   image: images.Nuwaan,
    //   name: "Nuwan Chathuranga",
    //   title: "Engineer",
    //   link: "https://www.linkedin.com/in/nuwan-chathuranga-5ba3853b/",
    // },
    {
      image: images.Nalantha,
      name: "Nalantha De Alwis",
      title: "Engineer",
      link: "https://www.linkedin.com/in/nalantha-de-alwis-2b923432/",
    },
    {
      image: images.Chammika,
      name: "Chammika Perera",
      title: "Engineer",
      link: "https://www.linkedin.com/in/chammika-perera-512135a9/",
    },
    {
      image: images.Chinthaka,
      name: "Chinthaka Nishantha",
      title: "Associate Engineer",
      link: "https://www.linkedin.com/company/softmatterio/mycompany/?viewAsMember=true",
    },
    // {
    //   image: images.Stefan,
    //   name: "Stefan Benedict",
    //   title: "Mechanical Engineer",
    //   link: "https://www.linkedin.com/in/stefan-benedict/",
    // },
    // {
    // image: images.Thilina,
    // name: "Thilina Rathnasooriya",
    // title: "Electronics Engineer",
    // link: "https://www.linkedin.com/in/thilina-rathnasooriya/",
    // },
    {
      image: images.Hashan,
      name: "Hashan Perera",
      title: "Associate Engineer",
      link: "https://www.linkedin.com/company/softmatterio/mycompany/?viewAsMember=true",
    },
  ]

  const operations_team = [
    {
      image: images.Kamal,
      name: "Kamal Ekanayake",
      title: "Operations Lead",
      link: "https://www.linkedin.com/in/kamal-ekanayaka-8b8ba13a/",
    },
    {
      image: images.Raweedra,
      name: "Raweendra Kumara",
      title: "NPI Lead",
      link: "https://www.linkedin.com/in/raweendra-kumara-07198474/",
    },
  ]

  return (
    <Layout>
      <Seo title="About" />
      <section>
        <BackgroundImage
          {...images.dusk_texure}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col w-full z-10 sm:mt-0 -mt-52">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    We are
                  </div>
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    Purpose Driven
                  </div>
                  <div className=" sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    We believe in postively
                    <div>impacting lives.</div>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className=" absolute right-0 bottom-0">
                  <StaticImage
                    src="../../images/leadership/Team banner.png"
                    alt="Softmatter"
                    placeholder="blurred"
                    width={820}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>

      <section>
        <div className="bg-dusk py-24 sm:text-left text-center overflow-hidden">
          <Fade bottom cascade>
            <div className="container mx-auto">
              <div className="">
                <div className="flex lg:flex-row flex-col items-center">
                  <div className="">
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/about/Ulysses.jpg"
                      width={2680}
                    />
                  </div>
                  <div className="lg:mt-0 mt-10 lg:pl-10">
                    <div className="text-mint text-2xl mb-2">Ulysses Wong</div>
                    <div className="text-mint text-xl mb-2">
                      Chief Executive Officer
                    </div>
                    <div className="text-white text-xl mb-2">
                      Ulysses leads the efforts in building and growing the
                      global business of Softmatter. Previously, Ulysses was the
                      President and CEO of Enbi, a Platinum Equity-owned global
                      manufacturing and engineering company. Prior to that,
                      Ulysses held VP-level business and customer engagement
                      positions at international corporations, including Flex,
                      Logitech, and IBM. Ulysses’ leadership roles have also
                      included General Manager, Sales and Marketing, Product
                      Management, Supply Chain Management, and Systems
                      Engineering.
                    </div>
                    <div className="text-mint">
                      <a
                        href="https://www.linkedin.com/in/ulyssesvwong/"
                        target="_blank"
                      >
                        <StaticImage
                          className="w-8 mt-2"
                          src="../../images/social/li_mint.png"
                          alt="Softmatter"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-mint text-4xl my-10">Marketing Team</div>
              <div className="">
                <div className="flex lg:flex-row flex-col items-center">
                  <div className="">
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/leadership/Tiasha.png"
                      width={2680}
                    />
                  </div>
                  <div className="lg:mt-0 mt-10 lg:pl-10">
                    <div className="text-mint text-2xl mb-2">
                      Tiasha Renganathan
                    </div>
                    <div className="text-mint text-xl mb-2">
                      Chief Customer Officer
                    </div>
                    <div className="text-white text-xl mb-2">
                      Having completed her BSc in Economics at University
                      College London and her MBA at UC Berkeley. Tiasha
                      currently gives leadership to the marketing function at
                      Softmatter. She has played a key role in developing the
                      Wearable Technology business vertical at MAS - onboarding
                      Fortune 500 companies in the Consumer Technology and
                      Consumer Health domains and positioning MAS as a market
                      leader in the space. In 2021 she was recognized among the
                      100 top leaders in the US at the Marketing, Advertising
                      and Retail Summit for contribution in the Fashion and
                      Technology sphere.
                    </div>
                    <div className="text-mint">
                      <a
                        href="https://www.linkedin.com/in/tiasha-renganathan-646b8095/"
                        target="_blank"
                      >
                        <StaticImage
                          className="w-8 mt-2"
                          src="../../images/social/li_mint.png"
                          alt="Softmatter"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <TeamSlider team={team} />
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section>
        <div className="bg-dusk pb-24 sm:text-left text-center overflow-hidden">
          <Fade bottom cascade>
            <div className="container mx-auto">
              <div className="text-mint text-4xl mb-10">Product Team</div>
              <div className="">
                <div className="flex lg:flex-row flex-col items-center">
                  <div className="">
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/leadership/Vishwa.png"
                      width={1550}
                    />
                  </div>
                  <div className="lg:mt-0 mt-10 lg:pl-10">
                    <div className="text-mint text-2xl mb-2">
                      Vishwa Aluthge
                    </div>
                    <div className="text-mint text-xl mb-2">
                      Chief Product Officer
                    </div>
                    <div className="text-white text-xl mb-2">
                      Vishwa holds a Bachelor’s degree specializing in Software
                      and Computer Science (BSc) from the University of Colombo.
                      He is a Fellow Member of the Chartered Institute of
                      Management Accountants UK (CIMA), and a certified PRINCE2
                      project management professional. He accounts over 15 years
                      of experience in the apparel sector.
                    </div>
                    <div className="text-mint">
                      <a
                        href="https://www.linkedin.com/in/vishwaaluthge/"
                        target="_blank"
                      >
                        <StaticImage
                          className="w-8 mt-2"
                          src="../../images/social/li_mint.png"
                          alt="Softmatter"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <TeamSlider team={product_team} />
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section>
        <div className="bg-dusk pb-24 sm:text-left text-center overflow-hidden">
          <Fade bottom cascade>
            <div className="container mx-auto">
              <div className="text-mint text-4xl mb-10">Operations Team</div>
              <div className="">
                <div className="flex lg:flex-row flex-col items-center">
                  <div className="">
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/leadership/Ross.png"
                      width={925}
                    />
                  </div>
                  <div className="lg:mt-0 mt-10 lg:pl-10">
                    <div className="text-mint text-2xl mb-2">
                      Ross D Alcazar
                    </div>
                    <div className="text-mint text-xl mb-2">
                      Chief Operations Officer
                    </div>
                    <div className="text-white text-xl mb-2">
                      Leading Softmatter in DFM/NPI, problem solving, customer
                      interaction and negotiation, and external partners (supply
                      chain) – building Softmatter and MAS operational
                      excellence in wearable technology.
                    </div>
                    <div className="text-white text-xl mb-2">
                      National speaker with a varieties of topics and subjects
                      on electronics and advanced mechanics platforms.
                    </div>
                    <div className="text-mint">
                      <a
                        href="https://www.linkedin.com/in/rossalcazar/"
                        target="_blank"
                      >
                        <StaticImage
                          className="w-8 mt-2"
                          src="../../images/social/li_mint.png"
                          alt="Softmatter"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                  {operations_team.map((item, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <GatsbyImage
                            className=""
                            placeholder="blurred"
                            image={item.image}
                            alt="Softmatter"
                          />
                        </div>
                        <div className="mt-4">
                          <div className="text-mint text-2xl">{item.name}</div>
                          <div className="text-mint text-xl mb-1">
                            {item.title}
                          </div>
                          <div className="text-mint text-base">
                            <a href={item.link} target="_blank">
                              <StaticImage
                                className="w-8 mt-2"
                                src="../../images/social/li_mint.png"
                                alt="Softmatter"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section>
        <div className="bg-dusk pb-24 sm:text-left text-center overflow-hidden">
          <Fade bottom cascade>
            <div className="container mx-auto">
              <div className="">
                <div className="flex lg:flex-row flex-col items-center">
                  <div className="">
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/leadership/Rasika.png"
                      width={1170}
                    />
                  </div>
                  <div className="lg:mt-0 mt-10 lg:pl-10">
                    <div className="text-mint text-2xl mb-2">Rasika Srilal</div>
                    <div className="text-mint text-xl mb-2">
                      Lead Consultant - Finance
                    </div>
                    <div className="text-white text-xl mb-2">
                      Rasika oversees the finance function of Softmatter.
                    </div>
                    <div className="text-white text-xl mb-2">
                      She was formally the Head of Finance of Twinery – MAS
                      Innovation, the innovation arm of MAS Holdings. Prior to
                      that, she held the position of Head of Operations of MAS
                      Innovation, working closely with MAS SBUs in driving
                      innovation strategy across the Group.
                    </div>
                    <div className="text-mint">
                      <a
                        href="https://www.linkedin.com/in/rasika-srilal-297750160/"
                        target="_blank"
                      >
                        <StaticImage
                          className="w-8 mt-2"
                          src="../../images/social/li_mint.png"
                          alt="Softmatter"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-4xl sm:text-6xl mb-4">
                  Connect with
                  <div>Softmatter</div>
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <button className="px-8 py-4 bg-mint  hover:bg-salmon/90 duration-200 text-white text-2xl">
                    Contact Us
                  </button>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query LeadershipAndTeams {
    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Tharushi: file(relativePath: { eq: "leadership/Tharushi.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Sarojini: file(relativePath: { eq: "leadership/Sarojini.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Praneeth: file(relativePath: { eq: "leadership/Praneeth.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Shezny_1: file(relativePath: { eq: "leadership/Shezny_1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Michael: file(relativePath: { eq: "leadership/Michael.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Nadine: file(relativePath: { eq: "leadership/Nadine.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Maduka: file(relativePath: { eq: "leadership/Maduka.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Nuwaan: file(relativePath: { eq: "leadership/Nuwaan.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Nalantha: file(relativePath: { eq: "leadership/Nalantha.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Chammika: file(relativePath: { eq: "leadership/Chammika.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Chinthaka: file(relativePath: { eq: "leadership/Chinthaka.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Stefan: file(relativePath: { eq: "leadership/Stefan.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Kamal: file(relativePath: { eq: "leadership/Kamal.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Raweedra: file(relativePath: { eq: "leadership/Raweedra.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Thilina: file(relativePath: { eq: "leadership/Thilina.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Hashan: file(relativePath: { eq: "leadership/Hashan.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default LeadershipAndTeams
